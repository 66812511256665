body {
  margin: 0;
  font-family: "Comic Sans MS";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary-container {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: black;
  margin-top: 2.5%;
}

.btn-primary-container .btn-primary {
  background-color: #FFDD00;
  color: black;
  padding: 10px 16px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 900;
  border: 3px #FFDD00 solid;
  transition-property: color, background-color, border-color;
  transition-timing-function: linear;
  transition: 0.4s;
  display: flex;
  align-items: center;
  width: fit-content;
}