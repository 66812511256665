@import url('https://fonts.cdnfonts.com/css/inria-sans');

/* Misisng Recipe Page */
#no-recipe-page {
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
    height: 100vh;
 
  }
  
/* Navbar custom styles */
.site {
    width: 100%;
}

.error-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

/* Error container */
.error-container {
    text-align: center;
    max-width: 800px;
    width: 100%;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
}

/* Error message and title */
.error-title {
    font-size: 2rem;
    font-weight: bold;
}

.error-message {
    font-size: 1.2rem;
    color: #6c757d; /* Slightly muted text */
    margin-top: 10px;
    line-height: 1.5;
}

/* Buttons */
.error-actions {
    margin-top: 30px;
}

/* Recipes Page*/
#recipe-page {
    background-color: #f4f4f4;
    font-family: 'Inria Sans';
}

.recipe-section {
    width: 70%;
    margin-left: 15%;
    margin-left: 15%;
}

.recipe-header-title-container {
    display: flex;
    align-items: baseline;
    gap: .5rem;
}

.fa-icon {
    font-size: 22px;
    cursor: pointer;
    color: #1E3050;
}

.recipe-header-title {
    margin: 0;
    font-size: 35px;
}

.recipe-header-subheader {
    margin: 0;
    padding: 3px 0;
    font-size: 18px;
}

.tooltip {
    position: relative;
    cursor: help; /* Makes it clear the element is interactive */
}

.tooltip-text {
    visibility: hidden;
    width: 220px; /* Adjust width as needed */
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    color: #fff; /* White text */
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    bottom: 120%; /* Position above the element */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0; /* Start as invisible */
    transition: opacity 0.3s ease, visibility 0.3s ease 0.3s; /* Delay visibility change */
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1; /* Make the tooltip visible on hover */
    transition-delay: 0s; /* Make visibility immediate on hover */
}

.video-container {
    position: relative;
    width: 100%; /* 80% of the screen width */
    padding-top: 60%; /* Adjust aspect ratio (reduce height) */
    margin: 0 auto; /* Center the container horizontally */
    overflow: hidden; /* Prevent overflow issues */
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; /* Removes the frame border */
}

.youtube-video-print {
    width: 100%;
}

.youtube-video-thumbnail {
    width: 100%;
}

.youtube-video-caption {
    font-style: italic;
    color: #656565;
    text-align: right;
    font-size: 14px;
}

.recipe-content-container {
    display: flex;
    margin-top: 2%;
    font-size: 18px;
}

.recipe-ingredients-column {
    height: 100%;
    width: 30%;
}

.recipe-instructions-column {
    height: 100%;
    width: 70%;
}

.recipe-ingredients-title {
    margin: 0;
    padding: 0;
    text-decoration: underline;
    font-size: 18px;
}

.recipe-ingredients-column ul {
    margin: 0;
    padding: 0;
    padding-bottom: 5%;
    list-style: none;
}

.recipe-instructions-column ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

.recipe-instructions-title {
    margin: 0;
    padding: 0;
    text-decoration: underline;
    font-size: 18px;
}


.recipe-item-header {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 18px;
}

.recipe-item-instructions {
    padding-bottom: 2%;
}

@media print{
    .video-container {
        padding: 0;
    }

    .youtube-video-web {
        display: none;
    }

    .return-to-recipes {
        display: none;
    }

    .fa-icon {
        display: none;
    }

    .youtube-video-caption {
        font-size: 10px;
    }

    .recipe-section {
        width: 95%;
        margin-left: 2.5%;
        margin-left: 2.5%;
    }

    .recipe-content-container {
        font-size: 14px;
    }

    .recipe-ingredients-title {
        font-size: 14px;
    }

    .recipe-instructions-title {
        font-size: 14px;
    }
    
    .recipe-item-header {
        font-size: 14px;
    }
}

@media screen{
    .youtube-video-print {
        display: none;
    }
}