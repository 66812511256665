@import url('https://fonts.googleapis.com/css?family=Borel');

/* ========================= Hero Banner Section ========================= */
.hero-banner {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.hero-content {
    position: absolute;
    top: 25%;
    margin: 5%;
    color: white;
    width: 600px;
}

.hero-content h1 {
    margin: 0;
    font-size: 65px;
    font-family: "Aktiv Grotesk Cd Trial";
}

.hero-content p {
    margin: 0;
    font-size: 18px;
}

.hero-image-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.hero-image {
    width: 100%;
    height: auto;
    
}

/* ========================= Recent Posts ========================= */
.recent-posts {
    margin: 2% 0;
}

.recent-posts-title {
    display: flex;
    justify-content: center;
    font-family: "Cooper BT";
    font-size: 50px;
    width: 100%;
    margin: 0;
    padding: 0;
}

.recent-posts-list {
    list-style: none;
    display: inline-flex;
}

.recent-post-img {
    width: 90%;
}

.recent-post-item .recent-post-header {
    margin: 0;
    font-size: 16px;
}

.recent-post-item .recent-post-created-date {
    margin: 0;
    font-size: 14px;
    color: #5e5d5d;
}

/* ========================= Featured Catalog Section ========================= */
.featured-catalog-section {
    height: 100vh;
    margin: 3% 2%;
}

.featured-catalog-title {
    display: flex;
    justify-content: center;
    font-family: "Cooper BT";
    font-size: 50px;
    width: 100%;
    margin: 0;
}

/* Container for the whole page layout */
.grid-container {
    display: flex;
    height: 80%;
}

/* Primary grid on the left side */
.grid-primary {
    display: flex;
    width: 70%; /* Take up 70% of the page width */
}

/* First 1x2 grid inside the primary grid (Item 1 and Item 2) */
.grid-1x2.first-grid {
    display: grid;
    grid-template-rows: 35% 65%;  /* Item 1 = 30% height, Item 2 = 70% height */
    width: 50%; /* Take up 50% of the primary grid width */
}

/* Second 1x2 grid inside the primary grid (Item 3 and Item 4) */
.grid-1x2.second-grid {
    display: grid;
    grid-template-rows: 50% 50%;  /* Item 3 and Item 4 = 50% height each */
    width: 50%; /* Take up 50% of the primary grid width */
}

/* Secondary grid on the right side */
.grid-secondary {
    width: 30%; /* Take up 30% of the page width */
}

/* 1x2 grid for the secondary section */
.grid-1x2.secondary-grid {
    display: grid;
    grid-template-rows: 50% 50%;  /* Item 5 and Item 6 = 50% height each */
    height: 100%;
}

/* General grid item styling */
.grid-item {
    background-color: #f0f0f0; /* Example background color */
    margin: 2%;
    text-align: center;
    border: 1px solid #ddd; /* Border for individual items */
    overflow: hidden;
}

.featured-post-img {
    object-fit: contain;
    width: 100%;
    height: auto;
}