.global-banner {
    display: flex;
    align-items: center;
    height: 5vh;
    /* background-color: #94b69a; */
    background-color: #FDE3B1;
    justify-content: space-between;
    overflow: hidden;
}

.long-banner {
    list-style: none;
    display: inline-flex;
    /* justify-content: space-between; */
    min-width:100%;
    animation: scroll 30s linear infinite;
    padding-left: 0;
    font-family: "Comic Sans MS", "Comic Sans", cursive;


}

.global-banner:hover ul {
    animation-play-state: paused;
}

.long-banner span {
    font-weight: 25px;
    margin-right: 20px;
    
}
.long-banner a[href=""] {
    display: none;
}


@media screen and (max-width: 1036px) {
    .global-banner {
        height: auto;
    }
    .long-banner {
        display: none;
    }
}

@keyframes scroll {
    to {
        transform: translateX(-100%);

    }
    from {
        transform: translateX(0);
    }
    
}
