.catalog-grid ol, .catalog-grid li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    /* width: 100%; */
}

.catalog-grid h2 {
    font-size: 2rem;
    margin: 20px 0 10px;
}

.catalog-grid p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.catalog-grid {
    width: 100%;
    height: 85%;
    text-align: center;
}

.catalog-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

.catalogitem {
    height: 100%;
    position: relative;
    background-color: transparent;
}

