.return-to-recipes {
    height: 5vh;
    display: flex;
    align-items: center;
    padding-left: 14%;
}

.icon-small {
    width: 30px; /* or whatever size you want */
    height: 30px;
    font-weight: 600;
}

.return-text {
    font-weight: 600;
    font-size: 18px;
}