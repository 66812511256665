.recipes-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    list-style: none;
    padding-left: 5%;
    padding-right: 5%;
}

.recipe-item {
    width: 250px;
    flex: 1 1 auto;
    max-width: 325px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Softer shadow */
    background-color: #f4f4f4;
    overflow: hidden;
    transition: box-shadow 0.2s ease, transform 0.2s ease; /* Shorter, smoother animations */
}

.recipe-item:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Slight shadow enhancement */
    transform: scale(1.025); /* Gentle scaling */
}

.recipe-link {
    text-decoration: none;
    color: black;
    display: block;
    padding: 10px;
    transition: color 0.2s ease; /* Subtle text color change */
}

.recipe-link:hover {
    color: #333; /* Slightly darker color for text */
}

.recipe-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    transition: transform 0.2s ease; /* Smoother image hover effect */
}

.recipe-header {
    font-size: 18px;
    color: black;
    margin: 0;
}

.recipe-created-date {
    font-size: 14px;
    color: #5e5d5d;
    margin: 0;
}