.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: white;
}

.contact-form {
    max-width: 600px;
    width: 100%;
    background-color: white;
    padding:  25px 25px 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    color: #333;
    margin: 25px;
}

.contact-form h2 {
    font-size: 30px;
    text-align: center;
}

.contact-form .input-box {
    margin-top: 20px;
}

.input-box .field {
    width: 100%;
    height: 50px;
    background-color: transparent;
    border: 2px solid #ddd;
    outline: none;
    border-radius: 6px;
    padding: 15px;
    font-size: 16px;
    color: #333;
    margin-top: 8px;
}

.input-box .field.message {
    height: 200px;
    resize: none;
}

.contact-form button {
    width: 100%;
    height: 55px;
    background-color: #618E68;
    border: none;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: 900;
    margin-top: 25px;
    transition: .5s;
}

.contact-form button:hover {
    background-color: #fcc201;
}