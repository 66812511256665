@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');

@import url("https://fonts.googleapis.com/css?family=Montserrat:500");

@import url('https://fonts.cdnfonts.com/css/aktiv-grotesk-cd-trial');


* {
    box-sizing: border-box;
}

.navbar-container {
    position: var(--position);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-right: 2%;
    padding-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}


.navbar-home {
    text-decoration: none;
    list-style: none;
    font-size: 30px!important;
    margin-top: .5rem;
    align-items: center;
    transition: transform 0.5s;
    font-family: "Aktiv Grotesk Cd Trial";
}

.navbar-social {
    font-size: 24px;
    transition: transform 0.5s;
}

.navbar-icons:hover {
    transform: scale(1.15);
}

.navbar-container ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1.25rem;
    color: var(--primary-color);
}

.navbar-nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .1rem;
    min-width: 20px;
    text-align: center;
}

.navbar-nav li {
    position: relative;
    display: block;
    transition: color 0.1s, background-color 0.1s;
    margin: 0;
    font-size: 18px;
    font-weight: 900;
    opacity: 70%;
    transition: 0.3s;
}

.navbar-nav li:hover {
    opacity: 100%;
}

@media (max-width: 850px) {
    .navbar-container {
        display: none;
    }
}