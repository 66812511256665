.footer {
    background-color: #cbdbce;
    height: 30vh;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.footer h1 {
    font-size: 90px;
}